<script setup lang="ts">
import { computed, ref } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppIcon, { IconSize } from "@/Components/Shared/icon/AppIcon.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";

export type AlertVariant =
    | "success"
    | "error"
    | "info"
    | "warning"
    | "default"
    | "borderless"
    | "flash";
export type AlertSize = "xsmall" | "small" | "regular" | "large";

type Props = {
    closeable?: boolean;
    variant?: AlertVariant;
    size?: AlertSize;
    iconSize?: IconSize;
    hideIcon?: boolean;
};
const {
    closeable = false,
    variant = "info",
    size = "regular",
    iconSize = "small",
    hideIcon = false,
} = defineProps<Props>();

// setup
const showAlert = ref(true);
const emit = defineEmits([
    "close",
]);
const slots = defineSlots();

// icon
const icon = computed(() => {
    if (variant.includes("success")) return "check";
    else if (variant.includes("error")) return "cross";
    else if (variant.includes("warning")) return "alert";
    return "info";
});
const iconStyle = computed(() => {
    const styles: any = [
        "inline-flex justify-center items-center shrink-0 text-white rounded-full",
    ];

    if (size === "xsmall") styles.push("w-4 h-4");
    else if (size === "small") styles.push("w-5 h-5");
    else if (size === "regular") styles.push("w-7 h-7");
    else if (size === "large") styles.push("w-8 h-8");

    if (variant.includes("success")) styles.push("bg-green-500");
    else if (variant.includes("error")) styles.push("bg-red-500");
    else if (variant.includes("info")) styles.push("bg-primary");
    else if (variant.includes("default")) styles.push("bg-slate-500");
    else if (variant.includes("warning"))
        styles.push("bg-yellow-500 text-white");
    return styles.join(" ");
});

// methods
const closeAlert = () => {
    if (closeable) {
        showAlert.value = false;
        emit("close");
    }
};

// classes
const classes: AntlerClasses<Props> = {
    base: "relative flex gap-3 items-start text-gray-600 bg-slate-100 rounded border",
    variants: {
        variant: {
            success: "border-green-200 bg-green-50 text-green-600",
            info: "border-primary/10 bg-primary/10 text-primary",
            warning: "border-yellow-200 bg-yellow-50 text-yellow-600",
            error: "border-red-200 bg-red-50 text-red-600",
            default: "border-slate-200 bg-slate-50 text-slate-500",
            borderless: "border-0",
            flash: "rounded-none",
        },
        size: {
            xsmall: "py-1.5 px-2 text-[13px] font-medium",
            small: "py-2 px-3 text-sm font-medium",
            regular: "py-3 px-4 text-base leading-relaxed font-medium",
            large: "py-4 px-4 text-base font-medium",
        },
    },
};
const { aClass } = installAntlerComponent("alert", { variant, size }, classes);
</script>

<template>
    <div
        v-if="showAlert"
        :class="[aClass({ 'pr-8': closeable })]"
        role="alert"
    >
        <div
            v-if="!hideIcon"
            :class="iconStyle"
        >
            <AppIcon
                :name="icon"
                :size="iconSize"
            />
        </div>

        <div>
            <slot />
        </div>

        <div class="ml-auto">
            <slot name="action" />
        </div>

        <div v-if="closeable && slots.close" class="absolute top-1/2 right-4 -translate-y-1/2">
            <slot name="close" />
        </div>
        <AppButton
            v-else-if="closeable"
            class="absolute top-1/2 right-4 -translate-y-1/2"
            icon="cross"
            variant="clean"
            @click="closeAlert"
        />
    </div>
</template>
